import axios from './index'

export default {

  getPrefItems(prefKey) {
    return axios.get(`/api/auth/med-pref-items?pref_key=${prefKey}`)
  },
  savePrefItems(prefKey, payload) {
    return axios.post(
      `/api/auth/med-pref-items?pref_key=${prefKey}`,
      payload
    )
  }

}
