import axios from './index'

export default {
  get(params) {
    return axios.get('/api/auth/medicines', { params })
  },
  getMedicines(params, refundStatus) {
    return axios.get('/api/auth/medicines?q=' + params + '&r=' + refundStatus)
    // return axios.get('http://localhost:8080/json/medicine-search.json')
  },
  getPrevMedicine(patientId) {
    return axios.get('/api/auth/past-medicines?patientId=' + patientId)
    // return axios.get('http://localhost:8080/json/prev-med.json')
  },
  getMedicineDetails(id) {
    return axios.get('/api/auth/medicines/' + id)
    // return axios.get('http://localhost:8080/json/prev-med.json')
  },
  getFavourites(params) {
    if (params !== '') {
      return axios.get('/api/auth/medicines-favs?patientId=' + params)
    } else {
      return axios.get('/api/auth/medicines-favs')
    }
    // return axios.get('http://localhost:8080/json/patient-fav.json')
  },
  saveFavourites(patientId, payload) {
    if (patientId) {
      return axios.post(
        '/api/auth/medicines-favs?patientId=' + patientId,
        payload
      )
    } else {
      return axios.post('/api/auth/medicines-favs', payload)
    }
  },
  deleteFav(patientId, id) {
    if (patientId) {
      return axios.delete('/api/auth/medicines-favs?patientId=' + patientId, {
        data: id
      })
    } else {
      return axios.delete(`/api/auth/medicines-favs`, { data: id })
    }
  },

  savePresDetails(presObj, statusCallback) {
    // console.log(JSON.stringify(presObj), 'pres obj')
    // statusCallback(0, true, 'prescription succesfully added')
    // statusCallback(1, false, 'Error Issuing prescription due to invalid patient Number')
  }
}
