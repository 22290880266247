<template>
  <div>
    <b-form-checkbox
      @change="showFavsData"
      v-model="showFavOnly"
      name="check-button"
      switch
      style="text-align:right; padding-right:30px; font-size: 12px;">
      <span style="display:inline-block; margin-top:2px">Show Only Favourites</span>
      <b-icon-star-fill variant="warning"></b-icon-star-fill>
    </b-form-checkbox>

    <multiselect
      v-model="searchModel"
      tagPlaceholder=""
      :placeholder="placeholder"
      :options="showFavOnly ? favouriteStorage : refreshedData"
      @search-change="handleInputValue"
      @select="handleSelect"
      track-by="label"
      label="label"
      :close-on-select="true"
      :show-labels="false"
      :showNoOptions="false"
      ref="multiselect">
      <template slot="option" slot-scope="props">
        <div>
          <span>
            <b-icon-star
              v-if="!props.option.favourites || props.option.favourites == undefined"
              @click="toggleFavourite(props)">
            </b-icon-star>
            <b-icon-star-fill
              variant="warning"
              v-if="props.option.favourites"
              @click="toggleFavourite(props)">
            </b-icon-star-fill>
            {{ props.option.label }}
          </span>
        </div>
      </template>
    </multiselect>
  </div>
</template>

<script>
import userPref from '../services/userPref'

export default {
  props: {
    data: { type: Array, required: true },
    required: { type: Boolean },
    initValue: { type: String },
    placeholder: { type: String },
    id: { type: String, required: true },
    addedFav: { type: Boolean }
  },
  data() {
    return {
      searchModel: null,
      showFavOnly: true,
      favouritesArray: [],
      favouriteStorage: []
    }
  },
  async mounted() {
    this.showFavOnly = this.addedFav || true
    if (this.addedFav) {
      this.showFavOnly = true
      this.$refs.multiselect.activate()
    }

    await this.refreshStorage()

    if (this.initValue) {
      this.searchModel = { label: this.initValue }
    }
  },
  computed: {
    refreshedData() {
      let ctr = 0
      let dataList = [...this.data]
      dataList.forEach((option) => {
        option.favourites = this.favouritesArray.some(f => f.label === option.label)
        if (option.favourites === true) {
          dataList[ctr]['favourites'] = true
        }
        ctr++
      })
      return dataList
    },
    filteredList() {
      return this.data.filter((el) => {
        for (const key of this.keys) {
          if (
            el[key] &&
            el[key].toLowerCase().search(this.search.toLowerCase()) > -1
          ) {
            return true
          }
        }
        return false
      })
    }
  },
  methods: {
    async refreshStorage() {
      const prefItems = await userPref.getPrefItems(this.id)
      this.favouritesArray = prefItems.data.data.items || []
      this.favouriteStorage = [...this.favouritesArray]

      // Update data with favourite flags
      let ctr = 0
      this.data.forEach((option) => {
        option.favourites = this.favouritesArray.some(f => f.label === option.label)
        if (option.favourites === true) {
          this.data[ctr]['favourites'] = true
        }
        ctr++
      })
    },
    handleSelect(option) {
      this.$emit('select', option.value)
    },
    handleInputValue(value) {
      this.$emit('input', value)
    },
    showFavsData() {
      // Update favouriteStorage from favouritesArray
      this.favouriteStorage = [...this.favouritesArray]
    },
    async toggleFavourite(props) {
      const option = props.option
      const isFavourited = option.favourites

      if (this.showFavOnly) {
        option.favourites = false
        // Remove from favourites
        this.favouritesArray = this.favouritesArray.filter(item => item.label !== option.label)
        this.showSuccessMessage(`${option.label} has been removed from favourites`)
      } else {
        if (isFavourited) {
          this.data[props.index]['favourites'] = false
          // Remove from favourites
          this.favouritesArray = this.favouritesArray.filter(item => item.label !== option.label)
          this.showSuccessMessage(`${option.label} has been removed from favourites`)
        } else {
        // Add to favourites
          option.favourites = true
          this.data[props.index]['favourites'] = true
          this.favouritesArray.push(option)
          this.showSuccessMessage(`${option.label} has been added to favourites`)
        }
      }

      // Save to backend and refresh
      await userPref.savePrefItems(this.id, { items: this.favouritesArray })
      await this.refreshStorage()
    },
    showSuccessMessage(message) {
      this.$swal(this.$t('success'), message, 'success')
    }
  }
}
</script>
